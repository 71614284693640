body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiTableCell-sizeSmall {
    padding: 2px !important;
}

.RaSidebar-drawerPaper-28 {
    width: auto !important;
}

[role="dialog"] {
    width: fit-content !important;
}

.MuiButton-textPrimary:hover {
    background-color: #E0E0E0 !important;
}

.MuiButton-root {
    border-radius: 0 !important;
}

.MuiButton-label {
    padding: 5px !important;
}

.MuiDialogTitle-root .MuiTypography-root {
    font-size: 1rem !important;
}
